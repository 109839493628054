.workout-squad {
	display: flex;
	flex-direction: column;
	gap: 12px;

	&__progress {
		position: sticky;
		top: 0;
		padding-top: 1px;
		background-color: var(--graphite-to-gray);
		z-index: 4;
		font-size: 1.15rem;
		padding-bottom: 12px;
	}
}
