@import 'src/app/styles/breakpoints';
.modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 120;
	backdrop-filter: blur(20px);
	background-color: var(--overlay-bg);
	transition: opacity 200ms ease-in-out, transform 200ms ease-in-out,
		backdrop-filter 200ms ease-in-out;
	animation: fade-in 0.2s ease-in-out;
	@supports (height: 100dvh) {
		height: 100dvh;
	}
	height: 100vh;
	&.high-priority {
		z-index: 250;
	}
	&.modal-out {
		opacity: 0;
		pointer-events: none;
		.modal-wrapper {
			transform: translateY(25px);
		}
	}
}
.modal-wrapper {
	--modal-height: 80vh;
	@supports (max-height: 80dvh) {
		--modal-height: 80dvh;
	}
	max-height: var(--modal-height);
	--modal-padding-block: 15px;
	--modal-padding-inline: 20px;
	animation: fade-in-transform 0.2s ease-in-out;
	z-index: 25;
	background-color: var(--main-bg);
	border-radius: 8px;
	padding: var(--modal-padding-block) var(--modal-padding-inline);
	width: 95%;
	max-width: 480px;
	position: relative;
	border: 1px solid var(--graphite);
	transition: all 200ms ease-in-out;
	box-shadow: 0 11px 34px 0 var(--shadow);
	--modal-title-height: 40px;
	--modal-logo-height: 60px;
	&[data-hide-logo='true'] {
		--modal-logo-height: 0px;
	}
	&[data-background='gray'] {
		background-color: var(--graphite);
	}
	.title {
		font-size: 28px;
		font-weight: 700;
		text-align: center;
		height: var(--modal-title-height);
	}
	@include sm {
		--modal-padding-inline: 0px;
		@supports (max-height: 100dvh) {
			--modal-height: 95dvh;
		}
		--modal-height: 95vh;
	}
	.logo {
		display: flex;
		justify-content: center;
		padding-bottom: 10px;
		height: var(--modal-logo-height);
	}

	.close {
		position: absolute;
		height: 32px;
		width: 32px;
		top: 10px;
		right: 10px;
		transition: transform 150ms ease-in-out;
		> svg {
			color: var(--white-to-ocean);
		}
		&:hover {
			transform: scale(1.1);
		}
	}
	&:has(.popup-account__logout) {
		max-width: 400px;
	}
}
.modal-content {
	overflow-y: auto;
	max-height: calc(
		var(--modal-height) -
			(var(--modal-title-height) + var(--modal-logo-height) + var(--modal-padding-block) * 2)
	);

	padding: 10px 20px;
	width: 100%;
	transition: all 200ms ease-in-out;
}
@keyframes fade-in-transform {
	0% {
		opacity: 0;
		transform: translateY(25px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}
@keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
