@import 'src/app/styles/breakpoints';
.products {
	--menu-sticky-offset: var(--global-header-height);
	display: flex;
	flex-direction: column;
	gap: 16px;
	--block-padding: calc(var(--main-x-padding) + 16px);
	&__content {
		padding-inline: var(--main-x-padding);
		padding-top: 20px;
		min-height: calc(100vh - var(--global-header-height) - var(--menu-size));
		overflow-y: auto;
		display: flex;
		@include lg {
			justify-content: center;
		}
	}
	&__list {
		padding-bottom: 20px;
		display: flex;
		gap: 16px;
		overflow: auto;
		height: fit-content;
		@include lg {
			flex-direction: column;
			padding-bottom: 36px;
		}
	}
}
