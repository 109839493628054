.wrapper {
	color: var(--white);

	display: flex;
	flex-direction: column;
	gap: 20px;
}

.segments {
	display: flex;
	justify-content: space-between;
	background-color: var(--graphite-soft);
	// max-width: 70%;
	justify-self: center;

	border-radius: 8px;
	position: relative;
}
.slider {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 50%;
	background-color: var(--white-soft-hard-to-soft-ocean);
	border-radius: 8px;
	transition: transform 0.2s ease-in-out;
}
.item {
	display: flex;
	justify-content: center;
	padding: 8px 0;
	width: 50%;
}
