@import 'src/app/styles/breakpoints';
.schedule-filter {
	--size: 16px;
	font-size: var(--size);
	overflow: hidden;
	transition: transform 0.3s ease-in-out, padding 0.3s ease-in-out, width 0.3s ease-in-out;
	padding-block: 25px;
	padding-inline: 0;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background-color: var(--main-bg);
	position: fixed;
	top: calc(var(--global-header-height) + var(--schedule-header-height));
	z-index: 12;
	left: 0;
	width: calc(270px + var(--main-x-padding));
	z-index: 10;
	color: var(--white);
	transform: translateX(calc(-100% - var(--main-x-padding)));
	height: calc(100vh - var(--global-header-height) - var(--schedule-header-height));
	padding-left: var(--main-x-padding);
	padding-right: 12px;
	&.active {
		overflow-y: auto;
		transform: translateX(0);
	}
	&__overlay {
		position: fixed;
		top: 0;
		right: 0;
		width: 100vw;
		height: 100vh;
		background-color: var(--white-soft-hard);
		backdrop-filter: blur(5px);
	}
	&__wrapper {
		display: flex;
		flex-direction: column;
		position: relative;
		overflow-y: auto;
		padding-right: 12px;
	}
	&__container {
		&__header {
			display: flex;
			gap: 20px;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 16px;
			width: 100%;
		}
		&__arrow {
			transform: rotate(180deg);
			transition: transform 0.3s ease-in-out;
			color: var(--white);
			&.active {
				transform: rotate(90deg);
			}
		}
		&__title {
			font-size: calc(var(--size) + calc(var(--size) / 2));
			font-weight: 700;
		}
		&__wrapper {
			display: grid;
			transition: grid-template-rows 0.3s cubic-bezier(0.73, 0.03, 0.25, 1),
				opacity 0.4s cubic-bezier(0.73, 0.03, 0.25, 1),
				margin 0.3s cubic-bezier(0.73, 0.03, 0.25, 1);
			overflow: hidden;
			grid-template-rows: 1fr;
			opacity: 1;
			margin-bottom: 24px;
			&.hide {
				grid-template-rows: 0fr;
				opacity: 0;
				margin-bottom: 0;
			}
		}
		&__list {
			display: flex;
			overflow: hidden;
			flex-direction: column;
			gap: 8px;
		}
	}
	&__footer {
		display: flex;
		flex-direction: column;
		position: sticky;
		bottom: 0;
		gap: 1rem;
		background-color: inherit;
		padding-block: 1rem;
		&__count {
			opacity: 0.5;
		}
	}
	.schedule-filter__close {
		top: 1rem;
	}
	@include md {
		padding-bottom: 10px;
		z-index: 101;
		top: var(--global-header-height);
		@supports (height: 100dvh) {
			max-height: calc(100dvh - var(--global-header-height));
		}
		max-height: calc(100vh - var(--global-header-height));
		height: 100%;
	}
}
