.tabs {
	--menu-size: 3rem;
	width: 100%;
	height: 100%;
	position: relative;
	&-navigation {
		&-wrapper {
			height: var(--menu-size);
			width: 100%;
			max-width: 100%;
			overflow-x: auto;
			overflow-y: hidden;
			-webkit-overflow-scrolling: touch;
			box-shadow: 0 1px 1px 0 #00000029;
			&.sticky {
				position: sticky;
				top: var(--menu-sticky-offset);
				background-color: var(--menu-background, var(--main-bg));
				z-index: 20;
			}
		}
		&-menu {
			display: flex;
			height: 100%;
			--tab-item-padding: 16px;
			padding-inline: calc(var(--block-padding) - var(--tab-item-padding));
			&-item {
				height: 100%;
				display: flex;
				padding-inline: var(--tab-item-padding);
				align-items: center;
				justify-content: center;
				position: relative;
				font-size: 1.125rem;
				font-weight: 700;
				white-space: nowrap;
				color: var(--secondary-text-color);
				border-radius: 10px 10px 0 0;
				transition-property: background-color, opacity;
				transition-duration: 0.2s;
				transition-timing-function: ease-in-out;
				@media (any-hover: hover) {
					&:hover {
						background-color: var(--light-secondary-text-color);
						&.active {
							background-color: var(--light-green-soft);
						}
					}
				}
				&.active {
					transition-duration: 0;
					color: var(--light-green);
					i {
						transition: transform 0.2s ease-in-out;
						opacity: 1;
					}
				}
				&-content {
					position: relative;
					white-space: nowrap;
					display: inline-flex;
					align-items: center;
					i {
						position: absolute;
						width: 100%;
						height: 3px;
						left: 0;
						bottom: -13.5px;
						opacity: 0;
						background-color: var(--light-green);
						pointer-events: none;
						border-radius: 3px 3px 0 0;
						z-index: 1;
						transform-origin: left;
						box-sizing: content-box;
					}
				}
			}
		}
	}
}
