@import 'src/app/styles/breakpoints';
.workout-detail-modal {
	--left: 0;
	--right: unset;
	--translateX: -20%;
	--workout-detail-padding-block: 32px;
	--workout-detail-padding-inline: 24px;
	--workout-detail-modal-height: 100vh;
	--workout-detail-modal-width: 450px;
	--workout-detail-header-height: 85px;
	--workout-detail-footer-height: 40px;
	--workout-detail-gap: 24px;
	--block-padding: var(--workout-detail-padding-inline);
	@supports (height: 100dvh) {
		--workout-detail-modal-height: 100dvh;
	}
	letter-spacing: 0.03em;
	position: fixed;
	top: 0;
	left: var(--left);
	right: var(--right);
	z-index: 122;
	width: var(--workout-detail-modal-width);
	background-color: var(--graphite-to-gray);
	padding-block: var(--workout-detail-padding-block);

	transition-property: transform, opacity, height, width;
	transition-timing-function: cubic-bezier(0.73, 0.03, 0.25, 1);
	transition-duration: 0.3s;
	animation: fade-details 0.3s cubic-bezier(0.73, 0.03, 0.25, 1);
	--workout-detail-size: 1.25rem;
	height: var(--workout-detail-modal-height);
	&[data-side='right'] {
		--left: unset;
		--right: 0;
		--translateX: 20%;
	}
	&__wrapper {
		position: relative;
		display: flex;
		flex-direction: column;
		gap: var(--workout-detail-gap);

		height: 100%;
	}
	&__header {
		display: flex;
		flex-direction: column;
		gap: 8px;
		height: var(--workout-detail-header-height);
		padding-inline: var(--workout-detail-padding-inline);
		&__title {
			font-size: calc(var(--workout-detail-size) + 0.125rem);
			font-weight: 700;
			line-height: 17px;
			margin-bottom: 8px;
			svg {
				width: calc(var(--workout-detail-size) + 0.25rem);
				height: calc(var(--workout-detail-size) + 0.25rem);
			}
		}
		&__level {
			font-size: 18px;
			font-weight: 400;
			gap: 12px;
			> svg {
				width: 26px;
				height: 26px;
			}
		}
		&__time {
			> svg {
				width: 26px;
				height: 26px;
			}
		}
	}

	&__footer {
		height: var(--workout-detail-footer-height);
		padding-inline: var(--workout-detail-padding-inline);
		width: 100%;
		flex-grow: 1;
		display: flex;
		align-items: end;
		&__wrapper {
			display: flex;
			gap: 16px;
			width: 250px;
			width: 100%;
		}
		&__share {
			transition: background-color 150ms ease-out;
			min-width: 40px;
			width: 40px;
			height: 40px;
			border-radius: 8px;
			display: grid;
			place-content: center;
			background-color: var(--gray-soft);
			position: relative;
			--text: 'Скопировать ссылку';
			> svg {
				color: var(--white-to-ocean);
			}
			&::before {
				content: '';
				transition: opacity 150ms ease-in-out;
				position: absolute;
				width: 15px;
				height: 15px;
				background-color: var(--main-bg);
				transform: rotate(45deg);
				top: -30px;
				opacity: 0;
				right: 10px;
				z-index: -1;
			}
			&::after {
				content: var(--text);
				position: absolute;
				top: -60px;
				right: 0;
				background-color: var(--main-bg);
				padding: 10px;
				border-radius: 4px;
				height: 20px;
				white-space: nowrap;
				opacity: 0;
				pointer-events: none;
				transition: opacity 150ms ease-in-out;
				color: var(--white-to-ocean);
				font-weight: 500;
			}
			&.copied {
				--text: 'Ссылка скопирована';
				background-color: var(--primary-green);
				> svg {
					color: #fff;
				}
				&:hover {
					background-color: var(--yellow);
					> svg {
						color: var(--deep-black-to-white);
					}
					&::after,
					&::before {
						opacity: 1;
					}
				}
			}
			&:hover {
				background-color: var(--white-soft);
				&::after,
				&::before {
					opacity: 1;
				}
			}
		}
	}
	&.detail-hidden {
		transform: translateX(var(--translateX));
		opacity: 0;
	}
	@include lg {
		--workout-detail-padding-block: 24px;
		--workout-detail-padding-inline: 16px;
		--workout-detail-modal-height: 95vh;
		@supports (height: 95dvh) {
			--workout-detail-modal-height: 95dvh;
		}
		will-change: transform;
		border-bottom-left-radius: 20px;
		border-bottom-right-radius: 20px;
		border-bottom: 1px solid var(--white-soft);
		--workout-detail-modal-width: 100%;
		max-width: 500px;
		left: 50%;
		right: unset;
		animation: transform 0.7s ease;
		transition-duration: 0.7s;
		transition-timing-function: ease;
		// animation: transform 0.5s linear;
		transform: translate3d(-50%, 0, 0);
		--workout-detail-size: 1.25rem;
		&__wrapper {
			padding-bottom: 10px;
			&::after {
				content: '';
				position: absolute;
				bottom: -3px;
				right: 50%;
				transform: translate(50%, 15px);
				height: 4px;
				width: 20%;
				border-radius: 10px;
				background-color: var(--white);
				animation: move 10s 5s ease-out infinite;
			}
		}
		&.detail-hidden {
			transition-duration: 0.7s !important;
			transform: translate3d(-50%, -200%, 0);
			opacity: 0;
		}
	}
}
@keyframes fade-details {
	0% {
		opacity: 0;
		transform: translateX(var(--translateX));
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}
@keyframes transform {
	0% {
		transform: translate3d(-50%, -200%, 0);
		// opacity: 0;
	}
	100% {
		// opacity: 1;
		transform: translate3d(-50%, 0, 0);
	}
}
@keyframes move {
	0% {
		transform: translate(50%, 15px);
	}
	10% {
		transform: translate(50%, 7px);
	}
	35% {
		transform: translate(50%, 15px);
	}
}
