@import 'src/app/styles/breakpoints';
.notifications {
	background-color: var(--graphite-to-gray);
	padding-block: 24px;
	display: flex;
	flex-direction: column;
	position: fixed;
	top: 0;
	right: 0;
	gap: 24px;
	height: 100vh;
	color: var(--white);
	width: 360px;
	@supports (height: 100dvh) {
		height: 100dvh;
	}

	transition: transform var(--notification-transition-timing)
			cubic-bezier(0, -0.02, 0.15, 0.98),
		filter calc(var(--notification-transition-timing) * 3)
			cubic-bezier(0, -0.02, 0.15, 0.98),
		opacity 2s ease-in;
	transform: translateX(100%);
	z-index: 300;
	opacity: 0;
	filter: blur(20px);
	&.active {
		opacity: 1;
		transform: translateX(0);
		filter: unset;
		transition: transform var(--notification-transition-timing)
				cubic-bezier(0, -0.02, 0.15, 0.98),
			filter var(--notification-transition-timing)
				cubic-bezier(0, -0.02, 0.15, 0.98);
	}
	@include sm {
		transform: translateY(-100%);
		width: 100vw;
		padding-bottom: 32px;
		&.active {
			transform: translateY(0);
			&::after {
				content: '';
				position: absolute;
				bottom: 0px;
				right: 50%;
				transform: translate(50%, -10px);
				height: 4px;
				width: 30%;
				border-radius: 10px;
				background-color: var(--white);
				animation: swipe-move 10s 5s ease-out infinite;
			}
		}
	}
	&__title {
		font-weight: 700;
		font-size: 20px;
		padding-inline: 24px;
	}
	&__content {
		display: grid;
		gap: 24px;
		padding-inline: 24px;
		overflow-y: auto;
	}
	.close-btn {
		background-color: var(--gray-soft);
		top: 16px;
		right: 16px;
		color: var(--white-to-ocean);
	}
}
.notifications-overlay {
	position: fixed;
	width: 100vw;
	height: 100vh;
	@supports (height: 100dvh) {
		height: 100dvh;
	}
	backdrop-filter: blur(20px);
	top: 0;
	left: 0;
	background-color: var(--overlay-bg);
	opacity: 0;
	pointer-events: none;
	transition: opacity var(--notification-transition-timing)
		cubic-bezier(0, -0.02, 0.15, 0.98);
	z-index: 290;
	&.active {
		pointer-events: all;
		opacity: 1;
	}
	@include sm {
		z-index: -1;
	}
}
@keyframes swipe-move {
	0% {
		transform: translate(50%, -10px);
	}
	10% {
		transform: translate(50%, -18px);
	}
	35% {
		transform: translate(50%, -10px);
	}
}
