.player-card {
	display: flex;
	gap: 10px;

	padding-block: 5px;
	border-radius: 8px;
	align-items: center;
	border: 1px solid transparent;
	&.current-player {
		color: var(--light-green);
	}
	&__avatar {
		display: grid;
		place-content: center;
		height: 40px;
		width: 40px;
		color: white;
		aspect-ratio: 1;
		border-radius: 50%;
	}
}
