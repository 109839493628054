.button {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px 20px;
	transition: all 150ms ease-in;
	line-height: 1;
	min-width: 0;
	white-space: nowrap;
	border-radius: 6px;
	font-weight: 500;
	position: relative;
	max-width: 192px;
	width: 100%;
	height: 40px;
	gap: 16px;
	cursor: pointer;
	&:disabled {
		opacity: 70%;
		pointer-events: none;
	}

	&:hover {
		filter: brightness(0.9);
	}
}

.primary {
	color: var(--black);
	border: 1px solid transparent;
	background-color: var(--yellow);
	.loader {
		border-top-color: var(--graphite);
	}
}
.transparent {
	background-color: transparent;
	border: 1px solid var(--light-white);

	&:hover {
		// color: var(--black);
		background-color: var(--light-white);
		border-color: transparent;
		filter: brightness(1);
	}
}
.green {
	color: #fff;
	border: 1px solid transparent;
	background-color: var(--primary-green);
}
.transparent-green {
	color: var(--white);
	border: 1px solid var(--primary-green);
	background-color: transparent;

	&:hover {
		color: var(--white);
		border-color: var(--dark-green);
		background-color: var(--dark-green);
	}
}
.black-green {
	color: var(--white);
	border: 1px solid var(--primary-green);
	background-color: var(--black-to-gray);

	&:hover {
		color: #fff;
		background-color: var(--primary-green);
		> svg {
			color: #fff;
		}
	}
}
.red {
	color: var(--white);
	border: 1px solid var(--fog-red);
	background-color: var(--black-to-gray);

	&:hover {
		color: #fff;
		background-color: var(--fog-red);
		.icon {
			color: #fff;
		}
	}
}
.icon {
	width: 1em;
	height: 0.875em;
	position: relative;
	top: 1px;
	color: var(--white);
}
.full-width {
	max-width: 100%;
}
.link {
	width: fit-content;
	color: var(--white);
	padding: 0;
	height: fit-content;
	transition: transform 250ms ease-in-out;
	&:hover {
		@media (any-hover: hover) {
			transform: scale(1.05);
			filter: brightness(1);
		}
	}
	&:disabled {
		opacity: 70%;
		z-index: 4;
		pointer-events: none;
	}
}
.centered {
	margin: auto;
}
.bold {
	font-weight: bold;
}
.loader {
	width: 1em;
	height: 1em;
	border-radius: 50%;
	display: inline-block;
	border-right: 3px solid transparent;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
	border-top: 3px solid var(--white);
	@keyframes rotation {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
}
