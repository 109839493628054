@import 'src/app/styles/breakpoints';
.workout-detail {
	display: flex;
	flex-direction: column;
	gap: 20px;
	&__group {
		display: flex;
		flex-direction: column;
		gap: 16px;
		&__title {
			color: var(--light-green);
			font-weight: 700;
			font-size: 14px;
			margin-bottom: -6px;
		}
	}
}
