@import 'src/app/styles/breakpoints';

.loader-products {
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding-inline: var(--main-x-padding);

	&__tabs {
		width: 70vw;
		border-radius: 8px;
	}
	&__content {
		display: grid;
		gap: 16px;
		grid-template-columns: repeat(3, 375px);
		overflow: auto;
		@include lg {
			place-content: center;
			grid-template-columns: minmax(0, 400px);
			padding-bottom: 36px;
		}
	}
	&__item {
		height: 480px;
		border-radius: 10px;
	}
}
