@import 'variables';
@import 'mixins';
@import 'src/app/styles/breakpoints';
// General
:root {
	--green: #72bf44;
	--primary-color: #337b3a;
	--white: #fff;
	--yellow: #faf62d;
	--black: #000;
	--grey: grey;
	--primary-bg: #337b3a;
	--secondary-bg: #292a2d;
	--light-white: #ffffff33;
	--slider-transition: cubic-bezier(0.645, 0.045, 0.355, 1);
	--notification-transition-timing: 400ms;
	--graphite: #292a2d;
	--shadow-green: #56ff3a80;
	--green-yellow: #adff2f;
	--white-soft-medium: #ffffff33;
	--light-gray: #7e7e7e0d;
	--ubuntu: 'Ubuntu', 'Roboto', Arial, sans-serif;
	--roboto: 'Roboto', Helvetica, Arial, sans-serif;
	--font-primary: 'Ubuntu', 'Roboto', Arial, sans-serif;
	--main-x-padding: 60px;
	--global-header-height: 90px;
	--global-footer-height: 104px;
	--schedule-header-height: 80px;
	--sub-header-height: 60px;
	@media screen and (max-width: 1440px) {
		--global-header-height: 80px;
		--main-x-padding: 30px;
	}
	@media screen and (max-width: 1024px) {
		--main-x-padding: 20px;
	}
	@include md {
		--main-x-padding: 15px;
		--global-header-height: 62px;
		--global-footer-height: 160px;
		--schedule-header-height: 144px;
	}
}

body {
	position: relative;
	min-height: 100vh;
	font-family: $font-text;
	line-height: 1.33;
	@supports (min-height: 100dvh) {
		min-height: 100dvh;
	}
}

// Typography
h1,
h2,
h3,
h4,
h5,
h6,
button {
	font-family: $ubuntu;
	color: var(--white);
}
iframe {
	border: none;
}
a {
	list-style-type: none;
	color: var(--white);
	transition: transform 0.3s ease-out;

	@media (any-hover: hover) {
		&:hover {
			transform: scale(1.05);
		}
		&.link-scale {
			transition: transform 0.6s ease-in-out;
			&:hover {
				text-shadow: none;
				transform: scale(1.05);
			}
		}
	}
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	transition: background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s;
}
button,
a {
	cursor: pointer;
}
* {
	scrollbar-color: rgba(119, 119, 133, 0.647) transparent;
	scrollbar-width: thin;
	&::-webkit-scrollbar {
		width: 5px;
	}
	&::-webkit-scrollbar-track {
		background: #292a2d;
	}
	&::-webkit-scrollbar-thumb {
		background-color: rgba(119, 119, 133, 0.647);
		border-radius: 20px;
		border: 1px solid #292a2d;
	}
}
.fade-in {
	animation: fade-in 0.3s ease-in-out;
}
@keyframes fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
.dropdown-icon {
	transform: rotate(0);
	transition: transform 0.2s linear;
	&.open {
		transform: rotate3d(1, 0, 0, 180deg);
	}
}
// Grid
.wrapper {
	max-width: 1440px;
	margin: 0 auto;
	padding: 0 165px 0 165px;
}

.container {
	position: relative;
	padding: 0 15px;
	transition: none;
	padding-bottom: 60px;
	padding-top: 20px;

	@media (min-width: $md) {
		padding: 0 30px;
		padding-bottom: 60px;
		padding-top: 20px;
	}

	@media (min-width: $lg) {
		margin: 0 auto;
		max-width: 1170px;
	}
}

// Page text
.page-title {
	margin-bottom: 30px;
	font-size: 24px;
	font-weight: 400;
	line-height: 1;

	@media (min-width: $lg) {
		margin-bottom: 60px;
		font-size: 40px;
	}
}

.page-subtitle {
	margin-bottom: 30px;
	font-size: 14px;

	@media (min-width: $lg) {
		margin-bottom: 60px;
		font-size: 18px;
	}
}

.details-list {
	font-size: 14px;

	@media (min-width: $md) {
		font-size: 18px;
	}

	&__item {
		margin-bottom: 20px;
		padding-bottom: 7px;
		@include borderB(1, dashed);

		&:last-child {
			margin-bottom: 0;
		}

		&-text {
			display: flex;
			justify-content: space-between;
			gap: 20px;

			&-value {
				font-weight: 700;
				color: $green;
				text-align: right;
			}
		}
	}
}

.details-title {
	margin-bottom: 15px;
	font-family: $font-title;
	font-size: 24px;
	color: $green;
	line-height: 1;
}

.price {
	display: flex;
	align-items: flex-end;
	gap: 20px;
	font-family: $font-title;

	&__current {
		font-size: 24px;
		line-height: 1;
		color: $green;
	}

	&__old {
		font-size: 16px;
		color: $red;
		text-decoration: line-through;
		opacity: 0.7;
	}
}

// Buttons
.btn {
	width: 100%;
	height: 50px;
	@include border();
	cursor: pointer;
	transition: 0.5s ease;

	&:hover {
		background-color: $green;
	}

	&--bg {
		background-color: $green;

		&:hover {
			color: $black;
			border-color: $yellow;
			background-color: $yellow;
		}
	}

	&--octagon {
		display: block;
		margin: 30px auto 0;
		max-width: 250px;
		clip-path: polygon(
			15px 0%,
			calc(100% - 15px) 0%,
			100% 15px,
			100% calc(100% - 15px),
			calc(100% - 15px) 100%,
			15px 100%,
			0% calc(100% - 15px),
			0% 15px
		);
	}
}

.no-reviews {
	font-size: 20px;
	color: $grey;
}

.add-review {
	display: block;
	margin: 45px auto 0;
	max-width: 445px;
}

// CKE editor style
.cke-description {
	h3 {
		margin-bottom: 15px;

		@media (min-width: $md) {
			margin-bottom: 30px;
		}
	}

	ul {
		font-size: 14px;

		@media (min-width: $md) {
			font-size: 18px;
		}

		li {
			margin-bottom: 20px;
			padding-bottom: 7px;
			@include borderB(1, dashed);
		}
	}

	table {
		width: 100%;
		font-size: 14px;
		@include border(0);
		border-collapse: collapse;

		@media (min-width: $md) {
			font-size: 18px;
		}

		tr {
			display: flex;
			justify-content: space-between;
			gap: 20px;
			padding-bottom: 7px;
			@include borderB(1, dashed);

			&:not(:last-child) {
				margin-bottom: 20px;
			}
		}

		td {
			padding: 0;
			border: 0;

			&:first-child {
				color: $white;
			}

			&:not(:first-child) {
				color: $green;
				text-align: right;
			}

			p {
				color: inherit;
			}
		}
	}
}

// Custom MUI dropdown
.MuiMenu-paper.dropdown-menu {
	margin-top: 5px;
	padding: 10px;
	border-radius: 0;
	background-color: $graphite;
	@include border();
	color: $white;

	.MuiMenu-list {
		padding: 0;
	}

	.MuiMenuItem-root {
		padding: 0;
		font-size: 12px;
		transition: 0.2s ease;

		&:not(:last-child) {
			margin-bottom: 10px;
		}

		@media (any-hover: hover) {
			&:hover {
				color: $yellow;
			}
		}
	}
}

// Custom MUI dialog
.MuiDialog-paper.dialog-popup {
	padding: 20px;
	border-radius: 0;
	@include border(6);
	background: $graphite;

	@media (min-width: $md) {
		padding: 50px;
		max-width: 700px;
		border-width: 10px;
	}

	.dialog-close {
		position: absolute;
		right: 16px;
		top: 16px;
		cursor: pointer;

		@media (min-width: $md) {
			right: 40px;
			top: 40px;
		}

		@media (min-width: $lg) {
			right: 50px;
			top: 50px;
		}
	}

	.dialog-title {
		margin-bottom: 30px;
		padding: 0 60px 0 0;
		font-size: 20px;
		font-family: $font-title;

		@media (min-width: $xs) {
			font-size: 24px;
		}

		@media (min-width: $lg) {
			margin-bottom: 40px;
			font-size: 30px;
		}
	}

	.dialog-content {
		padding: 0;
		color: $white;
	}

	.dialog-actions {
		margin-top: 50px;
		padding: 0;
		gap: 35px;

		@media (max-width: $max-sm) {
			display: block;
		}

		.btn {
			margin-left: 0;

			&:first-child {
				@media (max-width: $max-sm) {
					margin-bottom: 15px;
				}
			}
		}
	}
}

// Swiper
.swiper-slide img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.swiper-horizontal .swiper-pagination {
	bottom: 2px !important;
	height: 17px;

	@media (min-width: $xs) {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 4px;
	}

	@media (min-width: $md) {
		gap: 32px;
	}

	&-bullet {
		width: 11px;
		height: 11px;
		background: $white;
		opacity: 1;

		&-active {
			box-shadow: 0 0 0 3px $green;
		}
	}
}

// Other
.reviews {
	&__item:not(:last-child) {
		margin-bottom: 25px;

		@media (min-width: $md) {
			margin-bottom: 30px;
		}

		@media (min-width: $lg) {
			margin-bottom: 40px;
		}
	}
}

.media-wrapper {
	position: relative;

	video,
	iframe,
	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.no-scroll {
	overflow: hidden;
}

.hidden {
	display: none !important;
}

@media #{$tablet} {
	.wrapper {
		max-width: 744px;
		padding: 0 30px 0 30px;
	}
}

@media #{$mobile} {
	.wrapper {
		max-width: 390px;
		padding: 0 15px 0 15px;
	}
}
.main-logo {
	color: var(--white-soft-to-ocean);
}
.image-contain {
	object-fit: contain;
	width: 100%;
	height: 100%;
	background-color: transparent;
}
.image-cover {
	object-fit: cover;
	width: 100%;
	height: 100%;
	background-color: transparent;
}
.absolute-center {
	position: absolute;
	top: calc(50% - var(--global-header-height));
	left: calc(50% - var(--main-x-padding) - 45px);
}
.absolute-center-container {
	position: absolute;
	top: 50%;
	left: 50%;
}
.uppercase {
	text-transform: uppercase;
}
.capitalize {
	text-transform: capitalize;
}
.bold {
	font-weight: 700;
}
.fz-12 {
	font-size: 12px;
}
.fz-14 {
	font-size: 14px;
}
.bold-500 {
	font-weight: 500;
}

.form-field {
	z-index: 2;
	width: 100%;
	background-color: var(--graphite);
	padding: 10px 15px;
	border-radius: 5px;
	border: 1px solid #ffffff80;
	&[aria-invalid='true'] {
		border-color: crimson;
	}
	&:focus {
		background-color: #18191b;
	}
}
.green-highlight {
	color: var(--green);
}
.red-highlight {
	color: var(--red);
}
.ellipsis {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}
.fade-in {
	animation: fade-in 0.3s ease-in-out;
}
.scale-in {
	animation: scale-in 0.2s ease-out;
}
.hide-scroll {
	-webkit-overflow-scrolling: touch;
	scrollbar-width: none;
	overscroll-behavior: none;
	-ms-overflow-style: -ms-autohiding-scrollbar;
	::-webkit-scrollbar {
		display: none !important;
		height: 0;
		width: 0;
		opacity: 0;
		-webkit-appearance: none;
		&-thumb {
			background-color: unset;
			overflow: hidden;
			display: none;
		}
	}
}
.btn-loading {
	pointer-events: none;
	opacity: 0.7;
}
.center-container {
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 20px;
	padding-bottom: 40px;
}
.metro-icon__white {
	fill: white;
}
.metro-icon-size {
	width: 20px;
	height: 20px;
}

::view-transition-old(root),
::view-transition-new(root) {
	animation: none;
	mix-blend-mode: normal;
}
.loader-fade-in {
	animation: loader-fade-in 0.7s ease-in-out;
}
@keyframes fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@keyframes scale-in {
	from {
		transform: scale(0);
	}
	to {
		transform: scale(1);
	}
}
@keyframes loader-fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
