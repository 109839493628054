@import 'src/app/styles/breakpoints';
.sign-up {
	display: flex;
	flex-direction: column;
	gap: 24px;

	.title {
		font-size: 32px;
		font-weight: 700;
		text-align: center;
	}
	.form {
		display: flex;
		flex-direction: column;
		gap: 16px;
		> button {
			margin-inline: auto;
			max-width: 192px;
		}
	}
	.policy {
		border-bottom: 1.5px solid var(--white);
	}
	.form-control {
		position: relative;
		display: flex;
		flex-direction: column;
		gap: 5px;
		height: 45px;
		@include sm {
			> label {
				font-size: 11px;
			}
		}
	}
	.error {
		display: flex;
		gap: 4px;
		align-items: center;
		font-size: 12px;
		@include sm {
			font-size: 11px;
		}
		> svg {
			color: var(--red);
		}
	}
}
