@import '../../../assets/styles/variables';
@import 'src/app/styles/breakpoints';
.schedule-class {
	--size: 14px;
	position: relative;
	display: flex;
	flex-direction: column;
	border-radius: 6px;
	padding: 10px;
	font-size: var(--size);
	transition: opacity 150ms ease-in, transform 250ms ease-in;
	gap: 10px;
	padding: var(--size);
	filter: opacity(0.3);
	pointer-events: none;
	&.future-class {
		pointer-events: all;
		filter: opacity(1);
		&:hover {
			@media (hover: hover) {
				transform: scale(1.02);
				cursor: pointer;
			}
		}
		&.active {
			z-index: 122;
			position: sticky;
			transform: scale(1.02);
		}
	}
	&[data-color-id='1'] {
		background-color: var(--graphite-soft);
	}
	&[data-color-id='2'] {
		background-color: var(--graphite);
	}

	&.ended-class {
		opacity: 0.3;
		pointer-events: none;
	}

	&__content {
		display: flex;
		flex-direction: column;
		gap: calc(var(--size) - 6px);
		gap: 4px;
		letter-spacing: 0.06em;
	}
	&__leave-class__btn {
		width: 100%;
	}
	&__btn {
		max-width: 100%;
		letter-spacing: 0.06em;
		font-weight: 700;
		font-size: calc(var(--size) + 1px);
		@include xxl {
			height: 35px;
		}
		@include lg {
			height: 40px;
		}
	}
	&__join-class__btn {
		&:disabled {
			opacity: 0.3;
			letter-spacing: 0;
		}
	}
	&__actions {
		position: relative;
		z-index: 3;
		margin-bottom: 8px;
	}
	&__data {
		--icon-title-size: 24px;
		> svg {
			min-width: var(--icon-title-size);
			width: var(--icon-title-size);
			height: var(--icon-title-size);
		}
		@include xxl {
			--icon-title-size: 20px;
		}
		@include xl {
			--icon-title-size: 18px;
		}
		@include lg {
			--icon-title-size: 24px;
		}
	}
	&__level {
		margin-bottom: 4px;
	}
	&__progress-wrapper {
		position: relative;
		margin-top: 2px;
		&__icon {
			z-index: 15;
			position: absolute;
			top: 4.5px;
			right: 10px;
			width: 15px;
			height: 15px;
		}
	}
	&__free-leave-tip {
		font-size: calc(var(--size) - 4px);
		color: var(--white-soft-to-ocean);
		font-weight: 400;
		@include lg {
			display: flex;
			gap: 2px;
			align-items: center;
			justify-content: center;
		}
	}
	&__team-tooltip {
		position: absolute;
		right: 0;
		top: 0;
		border-radius: 0 8px 0 8px;
		display: grid;
		place-content: center;
		width: var(--tooltip-size);
		height: var(--tooltip-size);
		--tooltip-size: 35px;
		font-size: 18px;
		font-weight: 700;
		color: var(--black);
		> span {
			padding-bottom: 45%;
			padding-left: 30%;
			@include lg {
				padding: 0;
			}
		}
		&__text {
			z-index: 5;
		}

		&::before {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			height: 100%;
			width: 100%;
			background-color: var(--light-green-to-soft-ocean);
			border-radius: 0 6px 0 0;
			clip-path: circle(100% at 100% 0);
		}

		&::after {
			padding: 8px;
			background-color: var(--light-green-to-soft-ocean);
			backdrop-filter: blur(15px);
			position: absolute;
			top: calc(var(--tooltip-size));
			font-weight: 500;
			right: 5px;
			border-radius: 4px;
			opacity: 0;
			content: attr(data-tooltip);
			z-index: 50;
			pointer-events: none;
			transition: opacity 150ms ease-in-out;
		}
		@media (hover: hover) {
			&:hover {
				&::after {
					opacity: 1;
				}
			}
		}
		@include xxl {
			--tooltip-size: 25px;
			font-size: 14px;
		}
		@include lg {
			&::before,
			&::after {
				content: unset;
			}
			background-color: var(--white-soft-hard-to-soft-ocean);
			color: var(--white-to-ocean);
			width: auto;
			height: auto;
			border-radius: 4px;
			padding: 8px;
			font-size: 14px;
			font-weight: 500;
			top: 16px;
			right: 16px;
		}
	}

	&__modal-title {
		font-size: 28px;
		font-weight: 700;
		margin-bottom: 20px;
		@media screen and (max-width: 768px) {
			font-size: 22px;
		}
	}
	@include xxl {
		--size: 14px;
	}
	@include xl {
		--size: 12px;
	}
	@include lg {
		width: 100%;
		--size: 16px;
		padding: 24px;
	}
}
.close-button.workout-detail-close {
	top: 16px;
	right: 16px;
	background-color: var(--white-soft-hard-to-soft-ocean);
	border-radius: 8px;
	padding: 8px;
	> svg {
		color: var(--white-to-ocean);
	}
}
.workout-detail-overlay {
	position: fixed;
	width: 100vw;
	height: 100vh;
	backdrop-filter: blur(5px);
	top: 0;
	left: 0;
	z-index: 121;
	animation: overlay-detail-in 0.3s cubic-bezier(0.73, 0.03, 0.25, 1);
	@include lg {
		background-color: var(--overlay-bg);
		animation-duration: 0.7s;
	}
	&__hidden {
		transition: opacity 300ms ease-in-out;
		opacity: 0;
	}
}
.modal-cancel-appoint {
	&__subtitle {
		font-size: 1rem;
		margin-bottom: 10px;
		text-align: center;
	}
	&__actions {
		display: flex;
		justify-content: center;
		gap: 16px;
	}
}
.squad-modal {
	--modal-height: 90vh;
	@supports (max-height: 90dvh) {
		--modal-height: 90dvh;
	}
	&__title {
		display: block;
		font-size: 28px;
		margin-bottom: 20px;
		font-weight: 700;
		text-align: center;
	}
}
.join-class-modal {
	z-index: 150;
}
@keyframes overlay-detail-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
