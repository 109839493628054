@import 'src/app/styles/breakpoints';
.schedule-header {
	font-size: 1rem;
	position: sticky;
	top: var(--global-header-height);
	z-index: 12;
	height: var(--schedule-header-height);
	background-color: var(--graphite-to-gray);
	&__title {
		padding: 1rem var(--main-x-padding);
		display: flex;
		justify-content: space-between;
		text-transform: uppercase;
	}
	&__actions {
		display: flex;
		gap: 10px;
	}
	&__clean-filters {
		padding: 10px;
		width: 40px;
		height: 40px;
		transition: transform 0.3s ease-in-out;
		transform: scale(0);
		pointer-events: none;
		&.active {
			pointer-events: all;
			transform: scale(1);
		}
	}
	&__trigger-filters {
		padding: 10px;
		width: 40px;
		height: 40px;
		> svg {
			transition: fill 150ms ease-in, stroke 150ms ease-in;
			stroke: var(--white-to-ocean);
		}
		&.active {
			background-color: var(--primary-green);
			> svg {
				stroke: white;
				fill: white;
			}
		}
		&:hover {
			border-color: transparent;
			background-color: var(--primary-green-soft);
			> svg {
				stroke: white;
			}
		}
	}
	&__bar {
		min-height: 72px;
		padding: 1.25rem var(--main-x-padding);
		display: flex;
		gap: 20px;
		align-items: center;
	}
	@include md {
		&__bar {
			background-color: var(--dark-black-to-gray);
		}
	}
}
