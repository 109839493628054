.tabs-details {
	--tabs-details-gap: 20px;
	--item-padding-inline: calc(var(--workout-detail-padding-inline) / 2);
	margin-inline: calc(0px - var(--item-padding-inline));
	padding-top: var(--tabs-details-gap);
	padding-inline: var(--workout-detail-padding-inline);
	display: flex;
	flex-direction: column;
	z-index: 2;
	gap: var(--tabs-details-gap);
	height: calc(
		var(--workout-detail-modal-height) - var(--workout-detail-header-height) -
			var(--workout-detail-footer-height) - calc(var(--workout-detail-padding-block) * 2) -
			calc(var(--workout-detail-gap) * 2) - var(--menu-size)
	);
	&__content {
		position: relative;
		z-index: 1;
		overflow-x: hidden;
		overflow-y: hidden;

		&-wrapper {
			--transition-duration: 0.2s;
			height: 100%;
			will-change: transform;
			transition: transform var(--transition-duration) cubic-bezier(0.645, 0.045, 0.355, 1);
			display: flex;
			&__item {
				position: relative;
				padding-inline: var(--item-padding-inline);
				overflow-y: auto;
				min-width: 100%;
				overscroll-behavior: contain;
			}
		}
		&-observer {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 50px;
			transform: translateX(-70px);
			&[data-position='end'] {
				width: 100%;
			}
		}
	}
}
