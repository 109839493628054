.fallback {
	--fallback-height: 100vh;
	color: var(--white);
	@support (height: 100dvh) {
		--fallback-height: 100dvh;
	}
	display: grid;
	width: 100vw;
	height: calc(var(--fallback-height) - var(--global-header-height) - var(--global-footer-height));
	place-content: center;
	&__content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 16px;
	}
}
