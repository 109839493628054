.recovery {
	display: grid;
	grid-template-columns: 1fr 40px;
	gap: 5px;
	width: 100%;
	padding-block: 15px;
}
.form {
	display: flex;
	flex-direction: column;
	gap: 16px;
}
.button {
	width: 40px;
	padding: 0;
	> svg {
		width: 24px;
		height: 24px;
	}
}
