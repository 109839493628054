@import 'src/app/styles/breakpoints';

.progress-bar {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	position: relative;
	width: 100%;
	border-radius: 4px;
	background-color: var(--main-bg);
	&__line {
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: var(--primary-green-soft);
		border-radius: 4px;
	}
	&__wrapper {
		transition: width 150ms ease-out;
		position: absolute;
		border-radius: 4px;
		background-color: var(--primary-green);
		left: 0;
		height: 100%;
		max-width: 100%;
	}
	&__count {
		font-size: 0.875em;
		z-index: 1;
		color: #fff;
		line-height: 16px;
		font-weight: 500;
	}
}
