.back {
	position: absolute;
	height: 32px;
	width: 32px;
	top: 20px;
	left: 20px;
	transition: transform 150ms ease-in-out;
	> svg {
		height: 28px;
		width: 28px;
		color: var(--white-to-ocean);
	}
	&:hover {
		transform: scale(1.1);
	}
}
