.workout-detail-photo {
	display: flex;
	flex-direction: column;
	gap: 32px;

	#video-container {
		height: 100%;
		width: 100%;
		#video {
			border-radius: 4px;
			width: 100%;
			height: 100%;
		}
	}
}
