.sign-in {
	display: flex;
	flex-direction: column;
	gap: 16px;
}
.form {
	color: #fff;
	display: flex;
	flex-direction: column;
	gap: 16px;
}
.forgot-password {
	margin-top: -8px;
	font-size: 14px;
	text-decoration: underline;
}
.error-recovery-username {
	padding-top: 15px;
	display: flex;
	flex-direction: column;
	gap: 12px;
	> h4 {
		font-size: 20px;
		padding-left: 25px;
	}
}
.toast-recovery-username {
	width: 100%;
	max-width: 430px;
	> div:nth-child(1) {
		position: absolute;
		top: 30px;
		left: 10px;
	}
}
.write-button {
	width: fit-content;
	margin: auto;
}
