.app.light {
	--primary-green: #03749c;
	--dark-green: #1b5e20;
	--light-green: #03749c;
	--light-green-soft: #03739c27;
	--fog-red: #f52222;
	--fog-red: #d33838;
	--primary-green-soft: #03749c99;
	--light-green-to-soft-ocean: #0390c3bd;
	--black: #fff;
	--graphite-soft: #03749c1a;
	--white: #292a2d;
	--secondary-text-color: #292a2dab;
	--light-secondary-text-color: rgba(112, 117, 121, 0.08);
	--white-soft: #292a2d33;
	--gray: #7e7e7e;
	--blue: rgb(11 87 208 / 1);
	--white-soft-hard: #ffffff1a;
	--white-soft-medium: #292a2d0d;
	--graphite: #292a2d0d;
	--white-soft-hard-to-graphite-soft: #292a2d0d;
	--deep-black: #03749c1a;
	--white-soft-harder-to-soft-ocean: #03749c1a;
	--light-white: rgba(3, 114, 155, 0.2);
	--yellow: #f26901;
	--orange: #faf62d;
	--header-bg: #03749c;
	--footer-bg: #03749c;
	--black-to-ocean: #03749c;
	--main-bg: #f9faff;
	--black-to-gray: #7e7e7e0d;
	--white-to-ocean: #03749c;
	--green-to-orange: #f26901;
	--deep-black-to-white: #fff;
	--dark-black-to-white: #fff;
	--dark-black-to-gray: #eff0f5;
	--graphite-to-white: #f9faff;
	--dark-black-to-soft-ocean: #03749c1a;
	--graphite-to-soft-ocean: #03749c1a;
	--white-soft-to-ocean: #03749c;
	--gray-soft: #7e7e7e1a;
	--tooltip-color: #03749c1a;
	--white-soft-hard-to-soft-ocean: #03749c1a;
	--graphite-to-gray: #eff0f5;
	--skeleton-main: #ebebeb;
	--skeleton-highlight: #03749c1a;
	--overlay-bg: #18191b62;
	--error-bg: rgba(245, 34, 34, 0.14);
	--red: #f52222;
	--shadow: rgba(120, 120, 128, 0.16);
	--card-shadow: #0b1f332e;
}
