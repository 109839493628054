.confirm {
	display: flex;
	flex-direction: column;
	gap: 24px;
}
.container {
	display: flex;
	justify-content: center;
	gap: 16px;
}
.title {
	color: var(--white-soft);
	font-size: 14px;
	font-weight: 600;
	text-align: center;
}
.back {
	z-index: 10;
}
.loader {
	height: 60px;
	width: 45px;
	border-radius: 8px;
}
