@import './src/assets/styles/variables';
@import 'src/app/styles/breakpoints';

.header {
	padding: 1rem var(--main-x-padding);
	font-size: 1rem;
	position: sticky;
	top: 0;
	z-index: 120;
	background-color: var(--header-bg);
	background-color: var(--main-bg);
	color: var(--white);
	background-repeat: no-repeat;
	color: var(--white);
	height: var(--global-header-height);
	&__content {
		display: flex;
		align-items: center;
		height: 100%;
		justify-content: space-between;
		gap: 30px;
		@include xxxl {
			gap: 20px;
		}
	}

	&__logo {
		@include sm {
			width: 132px;
		}
	}

	&__nav {
		animation: fade 0.5s ease-in-out;
	}

	&__list {
		display: flex;
		gap: 20px;
		z-index: 105;
		@include xxxl {
			gap: 1rem;
		}
	}
	&__item {
		position: relative;
		border-radius: 4px;
		transition: all 0.3s ease-in-out;
		&:hover {
			background-color: var(--white-soft-hard-to-soft-ocean);
		}
		> a {
			transition: padding 0.3s ease-in-out;
			padding: 12px 32px;
			display: block;
			color: inherit;
			&:hover {
				transform: scale(1);
			}
			@include xl {
				padding: 12px 16px;
			}
		}
		&.active {
			background-color: var(--yellow);
			color: var(--deep-black-to-white);
			.header__item__dropdown__icon {
				fill: var(--deep-black-to-white);
			}
		}
		&__dropdown {
			padding: 12px 32px;
			cursor: pointer;
			transition: color 0.3s ease-in-out;
			&:hover {
				.header__item__dropdown__list {
					padding: 8px 16px;
					max-height: 300px;
					opacity: 1;
				}
				.header__item__dropdown__icon {
					transform: rotate(180deg);
				}
			}
			// overflow: hidden;
			&__title {
				display: flex;
				gap: 8px;
				align-items: center;
			}
			&__icon {
				fill: var(--yellow);
			}
		}

		&__dropdown__list {
			background-color: var(--main-bg);
			right: 0;
			position: absolute;
			max-height: 0;
			top: 55px;
			overflow: hidden;
			transition: all 0.3s ease-in-out;
			display: grid;
			opacity: 0;
			gap: 8px;
			border-radius: 4px;
			border: 1px solid var(--white-soft);
			&__item {
				text-wrap: nowrap;
				padding: 5px;
				> a {
					display: block;
					color: var(--white);
				}
			}
		}
	}

	&__icon {
		width: 30px;
		height: 30px;
		transition: 0.2s ease;
		animation: fade 0.3s ease-in-out;
		svg {
			width: 100%;
			height: 100%;
		}
		&__burger {
			transition: 0.3s ease-in-out;
		}
	}

	&__actions {
		display: flex;
		align-items: center;
		gap: 24px;
		&__userInfo {
			padding: 12px 16px;
			background-color: #ffffff0d;
			background-color: var(--white-soft-harder-to-soft-ocean);
			color: var(--white-to-ocean);
			border-radius: 8px;
			text-align: center;
			position: relative;
			&.near-class {
				min-width: 165px;
			}
			&::after {
				padding: 16px;
				position: absolute;
				background-color: var(--graphite-to-soft-ocean);
				background-color: var(--tooltip-color);
				top: 60px;
				right: -50px;
				color: var(--white-to-ocean);
				white-space: nowrap;
				border-radius: 4px;
				font-weight: 500;
				backdrop-filter: blur(20px);
				content: attr(data-text);
				transition: opacity 0.3s ease-in-out;
				pointer-events: none;
				opacity: 0;
			}
			&[data-counter='true'] {
				&::after {
					right: -70px;
				}
			}
			&:hover {
				@media (any-hover: hover) {
					cursor: default;
					&::after {
						opacity: 1;
					}
				}
			}
		}
		&__icon {
			transition: background-color 150ms ease-out, transform 150ms ease-out;
			width: 40px;
			height: 40px;
			border-radius: 8px;
			display: grid;
			place-content: center;
			background-color: var(--white-soft-hard-to-soft-ocean);
			> svg {
				color: var(--white-to-ocean);
			}
			&.bell {
				position: relative;
				animation: jump 4s 1s ease-in-out infinite;
				> svg {
					animation: ring 4s 2s ease-in-out infinite;
				}
			}
			&.logout {
				// color: #fff;
				> svg {
					width: 32px;
					height: 32px;
				}
			}
			&:hover {
				@media (any-hover: hover) {
					background-color: var(--white-soft-medium);
					transform: scale(1);
				}
			}
		}
		@include sm {
			gap: 12px;
		}
	}
	&__nav-mob {
		position: fixed;
		background-color: var(--main-bg);
		width: 80vw;
		left: 0;
		z-index: 110;
		color: var(--white);
		overscroll-behavior: none;
		top: var(--global-header-height);
		display: flex;
		flex-direction: column;
		gap: 2.5rem;
		transform: translateX(-100%);
		overflow-y: auto;
		transition: transform 480ms cubic-bezier(0.73, 0.03, 0.25, 1);
		height: 100vh;
		max-height: calc(100vh - var(--global-header-height));
		@supports (height: 100dvh) {
			height: 100dvh;
			max-height: calc(100dvh - var(--global-header-height));
		}
		max-width: 375px;
		&--open {
			transform: translateX(0);
			.header__nav-mob__item {
				transition: opacity 0.7s ease-in-out;
				opacity: 1;
			}
		}
		&__overlay {
			position: fixed;
			background-color: var(--overlay-bg);
			backdrop-filter: blur(5px);
			background-color: rgba(0, 0, 0, 0.319);
			height: 100vh;
			width: 100vw;
			top: 0;
			right: 0;
			z-index: 100;
			transform: translateX(100%);
			transition: opacity 480ms cubic-bezier(0.73, 0.03, 0.25, 1),
				transform 2s cubic-bezier(0.73, 0.03, 0.25, 1);
			opacity: 0;
			&.active {
				transition: opacity 480ms cubic-bezier(0.73, 0.03, 0.25, 1);
				opacity: 1;
				transform: translateX(0);
			}
			@supports (height: 100dvh) {
				height: 100dvh;
			}
		}
		&__list {
			padding-top: 20px;
			position: relative;
			display: flex;
			flex-direction: column;
			gap: 8px;
			transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
		}
		&__link {
			display: block;
			width: 100%;
			padding-block: 16px;
			padding-left: var(--main-x-padding);
			color: var(--white);
			transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
			&.active {
				border-right: 2px solid var(--primary-green);
				background-color: var(--dark-black-to-soft-ocean);
			}
			&:hover {
				transform: scale(1);
				background-color: var(--dark-black-to-soft-ocean);
			}
		}
		&__item {
			font-weight: 500;
			transition: opacity 0.3s ease-in-out;
			opacity: 0;
		}
		&__eventsInfo {
			padding-inline: var(--main-x-padding);
			display: flex;
			flex-direction: column;
			gap: 2.5rem;
			margin-bottom: 30px;
			@include sm {
				margin-bottom: 0;
			}
			&__tip {
				font-size: 0.75rem;
				color: #7e7e7e99;
				padding-top: 8px;
			}
		}
		&__theme {
			margin-left: var(--main-x-padding);
			display: flex;
			align-items: center;
			margin-bottom: 30px;
			min-height: 32px;
			.moon-switch {
				left: 0;
				color: var(--white-to-ocean);
			}
			.sun-switch {
				left: 0;
			}
			> span {
				padding-left: 40px;
				padding-top: 5px;
				white-space: nowrap;
			}
		}
	}
	--animation-move-plus: 20;
	--animation-move-minus: -18;
}

@keyframes fade {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@keyframes jump {
	0% {
		transform: translateY(0);
	}
	4% {
		transform: translateY(-10px);
	}
	7% {
		transform: translateY(0);
	}
	10% {
		transform: translateY(-3px);
	}
	13% {
		transform: translateY(0);
	}
	16% {
		transform: translateY(-2px);
	}
	17% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(0);
	}
}
@keyframes ring {
	0% {
		transform: rotate(0);
	}
	1% {
		transform: rotate(var(--animation-move-plus) deg);
	}
	3% {
		transform: rotate(var(animation-move-minus) deg);
	}
	5% {
		transform: rotate(34deg);
	}
	7% {
		transform: rotate(-32deg);
	}
	9% {
		transform: rotate(30deg);
	}
	11% {
		transform: rotate(-28deg);
	}
	13% {
		transform: rotate(26deg);
	}
	15% {
		transform: rotate(-24deg) translateY(0);
	}
	17% {
		transform: rotate(22deg);
	}
	19% {
		transform: rotate(-20deg);
	}
	21% {
		transform: rotate(18deg);
	}
	23% {
		transform: rotate(-16deg);
	}
	25% {
		transform: rotate(14deg);
	}
	27% {
		transform: rotate(-12deg);
	}
	29% {
		transform: rotate(10deg);
	}
	31% {
		transform: rotate(-8deg);
	}
	33% {
		transform: rotate(6deg);
	}
	35% {
		transform: rotate(-4deg);
	}
	37% {
		transform: rotate(2deg);
	}
	39% {
		transform: rotate(-1deg);
	}
	41% {
		transform: rotate(1deg);
	}

	43% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(0);
	}
}
